
<template>
    <svg :width="size + 'px'" :fill="color" viewBox="0 0 101.004 88.653">
        <path id="cancelar_icon" data-name="cancelar  icon" d="M9375.565,1538.9A23.122,23.122,0,0,1,9363.5,1522.3c-.123-.623-.2-1.245-.271-1.844-.031-.274-.062-.548-.1-.817v-.1l.047-2.957a.2.2,0,0,0,.031-.13,22.1,22.1,0,0,1,2.678-9.244,23.174,23.174,0,0,1,16.577-12.056c.621-.116,1.246-.189,1.852-.265l.81-.1h2.86l.956.109a23.5,23.5,0,0,1,21.014,22.337,21.929,21.929,0,0,1-2.718,12,22.963,22.963,0,0,1-19.68,12.375c-.463.045-.928.045-1.387.045A21.81,21.81,0,0,1,9375.565,1538.9Zm-.3-5.9a18.6,18.6,0,0,0,26.084-26.084Zm-3.47-3.483,26.085-26.082a18.551,18.551,0,0,0-24.108,1.212v-.012A18.63,18.63,0,0,0,9371.794,1529.513Zm-55.343,1.063a6.453,6.453,0,0,1-6.45-6.452V1456.8a2.783,2.783,0,0,1,2.785-2.777h26.059V1478.7l9.456-6.386,9.437,6.365V1454H9383.8a2.783,2.783,0,0,1,2.782,2.784v33.448h-1.387l-.568.033-.822.106c-.64.078-1.375.165-2.145.312a27.35,27.35,0,0,0-17.342,10.656h-28.838a1.035,1.035,0,0,0-1.032,1.034v5.408a1.031,1.031,0,0,0,1.032,1.03h24.753a26.689,26.689,0,0,0-1.212,4.749h-8.745a1.029,1.029,0,0,0-1.03,1.03v5.425a1.033,1.033,0,0,0,1.03,1.034h8.48c.073.64.163,1.358.291,2.1a30.119,30.119,0,0,0,2.432,7.427Z" transform="translate(-9309.501 -1453.499)" fill="#a5b2b7" stroke="rgba(0,0,0,0)" stroke-width="1"/>
    </svg>
</template>

<script>
export default {
    name: 'iconCancelations',
    props: {
        size: {
            type: Number,
            default: 20
        },
        color: {
            type: String,
            default: ''
        }
    }
}
</script>

<style scoped>
</style>

